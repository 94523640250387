import React from 'react'
import { 
  Heading, 
  Box, 
  Text,
  SimpleGrid,
  AspectRatio,
  Button,
  Center,
  Link
} from '@chakra-ui/react'
import SEO from './SEO';

function Omne() {
  return (
    <article>
      <SEO
        metaTitle="Niečo o mne - MaxiHypo"
        metaDesc="Dlhoročná prax s financovaním bývania - hodnotenie podmienok, výpočet hypoték, príprava podkladov k žiadosti a k čerpaniu hypotéky."
        name="Maxihypo.sk"
        type="article"
        author="maxihypo"
      />
      <Box boxShadow="inner" bgColor={"secondary.100"} className="title">
        <Heading
          as="h1"
          py="40px"
          px="20px"
          maxW={1260}
          mx="auto"
          textTransform="uppercase"
          color={"primary.900"}
        >
          Niečo o mne
        </Heading>
      </Box>
      <Box maxW={"960"} mx="auto" p="20px">
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={10}>
          <Box>
            <Text as={"p"} py="20px">
              Mám 15 ročnú prax v bankovníctve. Pracoval som vo viacerých
              bankách. Mám bohaté skúsenosti s financovaním bývania, hodnotením
              a prípravou podkladov k žiadostiam a k čerpaniu hypoték. Zároveň s
              refinancovaním a zmenami na úveroch počas splácania (predčasné
              splatenie, zmeny zabezpečenia a iné).
            </Text>
            <Center>
              <Link href="/za-kolko">
                <Button py="20px" link="/za-kolko">
                  Moje služby
                </Button>
              </Link>
            </Center>
          </Box>
          <AspectRatio
            maxW="400px"
            ratio={4 / 3}            
          >
            <iframe
              title="Výpočet hypotéky"
              src="https://www.maxihypo.sk/mh.mp4"
              allowFullScreen              
            />
          </AspectRatio>
        </SimpleGrid>
      </Box>
    </article>
  );
}

export default Omne
