const PV = (rate, nper, pmt, fv) => {
  let { x, y, pv } = Number;
  x = Math.pow(1 + rate, -nper); 
  y = Math.pow(1 + rate, nper);
  pv = - ( x * ( fv * rate - pmt + y * pmt )) / rate;

  if (pv < 4500) {
    return '0';
  }
  else {
  return  pv;
  }
}

export default PV;