import React from "react";
import {
  MdPhoneAndroid,
  MdOutlineAccessTime,
  MdOutlineFmdGood,
} from "react-icons/md";
import {
  Box,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  SimpleGrid,
  Icon,
  Center
} from "@chakra-ui/react";
//import { AddIcon } from "@chakra-ui/icons";

export default function Cards() {
  return (
    <section id="cards">
      <Box bg={"secondary.50"}>
        <Box maxW={"960"} mx="auto" py={16} >
          <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={10} >
            <Card bg={"white"}>
              <CardHeader>
                <Center>
                  <Icon
                    as={MdPhoneAndroid}
                    boxSize={20}
                    color={"white"}
                    bg={"secondary.500"}
                    border="0"
                    borderRadius="200px"
                    p="20px"
                  />
                </Center>
                <Center>
                  <Heading size="md" mt={8}>
                    KONTAKT
                  </Heading>
                </Center>
              </CardHeader>
              <CardBody>
                <Center>
                  <a href="tel:+421903202390">+421903202390</a>
                </Center>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
            <Card bg={"white"}>
              <CardHeader>
                <Center>
                  <Icon
                    as={MdOutlineAccessTime}
                    boxSize={20}
                    color={"white"}
                    bg={"secondary.500"}
                    border="0"
                    borderRadius="200px"
                    p="20px"
                  />
                </Center>
                <Center>
                  <Heading size="md" mt={8}>
                    PRACOVNÁ DOBA
                  </Heading>
                </Center>
              </CardHeader>
              <CardBody>
                <Center>V pracovné dni od 9:00 do 17:00</Center>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
            <Card bg={"white"}>
              <CardHeader>
                <Center>
                  <Icon
                    as={MdOutlineFmdGood}
                    boxSize={20}
                    color={"white"}
                    bg={"secondary.500"}
                    border="0"
                    borderRadius="200px"
                    p="20px"
                  />
                </Center>
                <Center>
                  <Heading size="md" mt={8}>
                    OBLASŤ PÔSOBENIA
                  </Heading>
                </Center>
              </CardHeader>
              <CardBody>
                <Center>LEVICE, ZLATÉ MORAVCE, ŽARNOVICA</Center>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </SimpleGrid>
        </Box>
      </Box>
    </section>
  );
}