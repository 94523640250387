import React from "react"
import { Box, Heading, List, ListItem, ListIcon, Text } from "@chakra-ui/react"
import { MdCheckCircle } from "react-icons/md";
import SEO from "./SEO";

function Zakolko() {
  return (
    <section>
      <SEO
        metaTitle="Za koľko … - MaxiHypo"
        metaDesc="Hypokalkulačka - online výpočet hypotéky podľa príjmu. Rýchlo a jednoducho si vypočítaj výšku hypotéky a mesačnej splátky."
        name="Maxihypo.sk"
        type="article"
        author="maxihypo"
      />
      <article>
        <Box boxShadow="inner" bgColor={"secondary.100"} className="title">
          <Heading
            as="h1"
            py="40px"
            px="20px"
            maxW={1260}
            mx="auto"
            textTransform="uppercase"
            color={"primary.900"}
          >
            Za koľko
          </Heading>
        </Box>
        <Box maxW={"960px"} mx="auto" px={4} py={16}>
          <Heading as="h2" textTransform="uppercase" fontSize="2xl">
            Sprostredkovanie financovania bývania a poradenstvo je bezplatné
          </Heading>
          <Text my={8}>Služby zahŕňajú aj:</Text>
          {/* <SimpleGrid
            columns={{ sm: 1, md: 2, lg: 2 }}
            spacing={5}
            mx="auto"
            
          > */}
          <Box
            bgColor={"primary.100"}
            p={8}
            border="1px solid"
            borderColor={"primary"}
            borderRadius="6px"
            my={8}
          >
            <Heading as={"h3"} fontSize="xl" pb={4}>
              Analýzu
            </Heading>
            <List>
              <ListItem>
                <ListIcon as={MdCheckCircle} color="primary.500" />
                analýzu potrieb
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color="primary.500" />
                vyhodnotenie rozpočtu domácnosti a možností financovania
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color="primary.500" />
                prieskum a porovnanie aktuálnych podmienok financovania
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color="primary.500" />
                posúdenie typu príjmov (napr. zamestnanec v zahraničí, SZČO
                v SR, SZČO v zahraničí, zamestnanec vlastnej s.r.o. a pod.)
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color="primary.500" />
                analýzu možností zabezpečenia úveru (kupovaná nehnuteľnosť,
                pozemok a pod.)
              </ListItem>
            </List>
          </Box>

          <Box
            bgColor={"primary.100"}
            p={8}
            border="1px solid"
            borderColor={"primary"}
            borderRadius="6px"
            my={8}
          >
            <Heading as={"h3"} fontSize="xl" pb={4}>
              Prehľad riešení
            </Heading>
            <List>
              <ListItem>
                <ListIcon as={MdCheckCircle} color="primary.500" />
                prezentáciu podmienok a možností financovania v jednotlivých
                bankách
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color="primary.500" />
                sumarizáciu výhod a nákladov
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color="primary.500" />
                sprostredkovanie znalca k ohodnoteniu nehnuteľnosti
              </ListItem>
            </List>
          </Box>
          <Box
            bgColor={"primary.100"}
            p={8}
            border="1px solid"
            borderColor={"primary"}
            borderRadius="6px"
            my={8}
          >
            <Heading as={"h3"} fontSize="xl" pb={4}>
              Spracovanie žiadosti
            </Heading>
            <List>
              <ListItem>
                <ListIcon as={MdCheckCircle} color="primary.500" />
                prípravu, spracovanie a podanie žiadosti o úver
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color="primary.500" />
                spoluprácu pri podpise zmluvnej dokumentácie (úverová a záložná
                zmluva)
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color="primary.500" />
                podporu pri zabezpečovaní podmienok k čerpaniu finančných
                prostriedkov
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color="primary.500" />
                sprostredkovanie dofinancovania kúpnej ceny, príp. rekonštrukcie
                pri nadobudnutí bývania
              </ListItem>
            </List>
          </Box>
          <Box
            bgColor={"primary.100"}
            p={8}
            border="1px solid"
            borderColor={"primary"}
            borderRadius="6px"
            my={8}
          >
            <Heading as={"h3"} fontSize="xl" pb={4}>
              Podporu a servis
            </Heading>
            <List>
              <ListItem>
                <ListIcon as={MdCheckCircle} color="primary.500" />
                príprava podkladov k načerpaniu úveru
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color="primary.500" />
                poistenie nehnuteľnosti a zabezpečenie vinkulácie
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color="primary.500" />
                podporu aj po poskytnutí úveru (informácie napr. k požiadavkám
                na zmenu záložného práva, k predčasnému splateniu úveru alebo
                jeho časti a pod.)
              </ListItem>
            </List>
          </Box>
          {/* </SimpleGrid> */}
        </Box>
      </article>
    </section>
  );
}

export default Zakolko
