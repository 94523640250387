import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import metaImg from "../static/MaxiHypo.webp";

function SEO({metaTitle, metaDesc, name, type, author}) {

  return (
    <HelmetProvider>  
    <Helmet>
      <title>{metaTitle} - MaxiHypo</title>
      <meta name="description" content={metaDesc} />
      <meta
        name="robots"
        content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
      ></meta>
      <meta property="og:locale" content="sk_SK"></meta>
      <meta property="og:type" content={type}></meta>
      <meta property="og:title" content={metaTitle}></meta>
      <meta property="og:description" content={metaDesc}></meta>
      <meta property="og:url" content={window.location.href} />
      <meta property="og:site_name" content={name} />
      <meta property="og:updated_time" content="2022-10-07T20:18:11+02:00" />
      <meta property="og:image" content={metaImg} />
      <meta property="og:image:secure_url" content={metaImg} />
      <meta property="og:image:width" content="1920" />
      <meta property="og:image:height" content="1080" />
      <meta property="og:image:alt" content={name} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta
        property="article:published_time"
        content="2016-01-29T09:51:22+01:00"
      />
      <meta
        property="article:modified_time"
        content="2022-10-07T20:18:11+02:00"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDesc} />
      <meta name="twitter:image" content={metaImg} />
      <meta name="twitter:label1" content="Written by" />
      <meta name="twitter:data1" content={author} />
      <meta name="twitter:label2" content="Time to read" />
      <meta name="twitter:data2" content="4 minutes" />
    </Helmet>
    </HelmetProvider>
  );
}
export default SEO;