// src/Data.js
const optionsKids = [
  //{ value: null, label: "Počet detí", price: "" },
  { value: "0", label: "Žiadne", price: 0 },
  { value: "1", label: "1 dieťa", price: 125.11 },
  { value: "2", label: "2 deti", price: 250.22 },
  { value: "3", label: "3 deti", price: 375.33 },
  { value: "4", label: "4 deti", price: 500.44 },
  { value: "5", label: "5 detí", price: 625.55 },
];
const optionsAge = [
  { value: "18", label: "18 rokov", price: 18 },
  { value: "19", label: "19 rokov", price: 19 },
  { value: "20", label: "20 rokov", price: 20 },
  { value: "21", label: "21 rokov", price: 21 },
  { value: "22", label: "22 rokov", price: 22 },
  { value: "23", label: "23 rokov", price: 23 },
  { value: "24", label: "24 rokov", price: 24 },
  { value: "25", label: "25 rokov", price: 25 },
  { value: "26", label: "26 rokov", price: 26 },
  { value: "27", label: "27 rokov", price: 27 },
  { value: "28", label: "28 rokov", price: 28 },
  { value: "29", label: "29 rokov", price: 29 },
  { value: "30", label: "30 rokov", price: 30 },
  { value: "31", label: "31 rokov", price: 31 },
  { value: "32", label: "32 rokov", price: 32 },
  { value: "33", label: "33 rokov", price: 33 },
  { value: "34", label: "34 rokov", price: 34 },
  { value: "35", label: "35 rokov", price: 35 },
  { value: "36", label: "36 rokov", price: 36 },
  { value: "37", label: "37 rokov", price: 37 },
  { value: "38", label: "38 rokov", price: 38 },
  { value: "39", label: "39 rokov", price: 39 },
  { value: "40", label: "40 rokov", price: 40 },
  { value: "41", label: "41 rokov", price: 41 },
  { value: "42", label: "42 rokov", price: 42 },
  { value: "43", label: "43 rokov", price: 43 },
  { value: "44", label: "44 rokov", price: 44 },
  { value: "45", label: "45 rokov", price: 45 },
  { value: "46", label: "46 rokov", price: 46 },
  { value: "47", label: "47 rokov", price: 47 },
  { value: "48", label: "48 rokov", price: 48 },
  { value: "49", label: "49 rokov", price: 49 },
  { value: "50", label: "50 rokov", price: 50 },
  { value: "51", label: "51 rokov", price: 51 },
  { value: "52", label: "52 rokov", price: 52 },
  { value: "53", label: "53 rokov", price: 53 },
  { value: "54", label: "54 rokov", price: 54 },
  { value: "55", label: "55 rokov", price: 55 },
  { value: "56", label: "56 rokov", price: 56 },
  { value: "57", label: "57 rokov", price: 57 },
  { value: "58", label: "58 rokov", price: 58 },
  { value: "59", label: "59 rokov", price: 59 },
  { value: "60", label: "60 rokov", price: 60 },
  { value: "61", label: "61 rokov", price: 61 },
  { value: "62", label: "62 rokov", price: 62 },
  { value: "63", label: "63 rokov", price: 63 },
  { value: "64", label: "64 rokov", price: 64 },
  { value: "65", label: "65 rokov", price: 65 },
];


export { optionsAge, optionsKids };
