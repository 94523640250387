import React, { useState } from "react";
import {
  Box,
  Center,
  FormControl,
  Input,
  Select,
  Image,
  Button,
  Text,
  Heading,
  Switch,
  SimpleGrid,
  Link,
  useBoolean,
  Tooltip,
  Flex,
  Spacer,
  Textarea,
  Checkbox,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { QuestionIcon } from "@chakra-ui/icons";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { MdSend, MdRefresh, MdCalculate } from "react-icons/md";
import SEO from "./SEO";
import { optionsAge, optionsKids } from "./Data";
import PV from "./PV";
import PMT from "./PMT";
import jedenImg from "../static/jeden.webp";
import dvajaImg from "../static/dvaja.webp";

const Hypoteka = () => {
  const href = window.location.href;
  const { register, handleSubmit, watch, setValue, reset, getValues } =
    useForm();

  const getCoefficient = (age) => {
    if (age <= 40) {
      return 8;
    } else if (age > 60) {
      return 3;
    } else {
      return 8 - (age - 40) * 0.25;
    }
  };

  const urokovaSadzba = 4;

  //State variables dtiNewFirst and dtiNewFirst to store the old DTI values
  const [dtiNewFirst, setDtiNewFirst] = useState(0);
  const [dtiNewSecond, setDtiNewSecond] = useState(0);

  //State variables dtiOldFirst and dtiOldSecond to store the old DTI values
  const [dtiOldFirst, setDtiOldFirst] = useState(0);
  const [dtiOldSecond, setDtiOldSecond] = useState(0);

  //State variables for outcomes
  const [monthlyRepaymentTotal, setMonthlyRepaymentTotal] = useState(0);
  const [currentLoanBalanceTotal, setCurrentLoanBalanceTotal] = useState(0);
  const [allowedOverdraftTotal, setAllowedOverdraftTotal] = useState(0);

  //Porovnanie veku
  const [olderUserAge, setOlderUserAge] = useState(0);
  const [ageDifferenceTo70, setAgeDifferenceTo70] = useState(0);
  const [ageDifferenceTo65, setAgeDifferenceTo65] = useState(0);

  const incomeFirst = parseFloat(watch("incomeFirst", 0));
  const incomeSecond = parseFloat(watch("incomeSecond", 0));
  const [incomeTotal, setIncomeTotal] = useState(0);

  //Toggle switch state
  const [toggleValue, setToggleValue] = useState(false);
  const [secondFieldsVisible, setSecondFieldsVisible] = useState(false);

  //Set state for DTI and DTIOld results
  const [dtiNewResult, setDtiNewResult] = useState(0);
  const [dtiOldResult, setDtiOldResult] = useState(0);
  //const [dtiNewTotal, setDtiNewTotal] = useState(0);

  //Set state for livingWage
  const [livingWageTotal, setLivingWageTotal] = useState(0);

  //Set state for MaxSplatka
  const [maxSplatka, setMaxSplatka] = useState(0);

  //Set state for PV
  const [pvNew, setPvNew] = useState(0);
  const [pvOld, setPvOld] = useState(0);

  //Set state for Compare dti and pv
  const [compareNew, setCompareNew] = useState(0);
  const [compareOld, setCompareOld] = useState(0);
  const [compareTotal, setCompareTotal] = useState(0);

  const [pmtt, setPmtt] = useState(0);

  const [ageCalcOne, setAgeCalcOne] = useState(0);
  const [ageCalcTwo, setAgeCalcTwo] = useState(0);
  //const [urokovaSadzba, setUrokovaSadzba] = useState(0);

  //Helper - Get values from option "price"
  const getPriceFromOptions = (options, selectedValue) => {
    const selectedOption = options.find(
      (option) => option.value === selectedValue
    );
    return selectedOption ? selectedOption.price : 0;
  };

  //handle first toggle change
  const handleToggleChange = (event) => {
    setToggleValue(event.target.checked);
    if (!event.target.checked) {
      reset({
        ...getValues(),
        incomeSecond: "",
        ageSecond: "",
        monthlyRepaymentSecond: "",
        currentLoanBalanceSecond: "",
        allowedOverdraftSecond: "",
        kidsSecond: "",
      });
    }
  };
  React.useEffect(() => {
    if (!toggleValue) {
      setSecondFieldsVisible(false);
    }
  }, [toggleValue]);

  //Toggle the second
  React.useEffect(() => {
    if (!secondFieldsVisible) {
      setValue("kidsSecond", "");
      setValue("monthlyRepaymentSecond", "");
      setValue("currentLoanBalanceSecond", "");
      setValue("allowedOverdraftSecond", "");
    }
  }, [secondFieldsVisible, setValue]);

  //Age
  const ageFirst = watch("ageFirst", 18);
  const [coefficient, setCoefficient] = useState(getCoefficient(18));
  React.useEffect(() => {
    setCoefficient(getCoefficient(parseInt(ageFirst || 0, 10)));
  }, [ageFirst]);

  React.useEffect(() => {
    const ageFirst = parseInt(watch("ageFirst", 18));
    const ageSecond = parseInt(watch("ageSecond", 18));
    const olderAge = Math.max(ageFirst || 0, ageSecond || 0);
    setOlderUserAge(olderAge);
    setAgeDifferenceTo70(70 - olderAge);
    setAgeDifferenceTo65(65 - olderAge);
  }, [watch("ageFirst"), watch("ageSecond")]);

  const ageSecond = watch("ageSecond", 18);
  const [coefficientSecond, setCoefficientSecond] = useState(
    getCoefficient(18)
  );
  React.useEffect(() => {
    setCoefficientSecond(getCoefficient(parseInt(ageSecond || 0, 10)));
  }, [ageSecond]);

  //income
  React.useEffect(() => {
    const incomeFirst = parseFloat(watch("incomeFirst", 0));
    const newDtiNewFirst = calculateDTINew(incomeFirst, coefficient);
    setDtiNewFirst(newDtiNewFirst);
    const newDtiOldFirst = calculateDTIOld(incomeFirst, coefficientSecond);
    setDtiOldFirst(newDtiOldFirst);
  }, [watch("incomeFirst"), coefficient, coefficientSecond]);

  React.useEffect(() => {
    const incomeSecond = parseFloat(watch("incomeSecond", 0));
    const newDtiNewSecond = calculateDTINew(incomeSecond, coefficient);
    setDtiNewSecond(newDtiNewSecond);
    const newDtiOldSecond = calculateDTIOld(incomeSecond, coefficientSecond);
    setDtiOldSecond(newDtiOldSecond);
  }, [watch("incomeSecond"), coefficient, coefficientSecond]);

  //Income Total
  React.useEffect(() => {
    const incomeFirst = parseFloat(watch("incomeFirst", 0));
    const incomeSecond = parseFloat(watch("incomeSecond", 0));
    //Výpočet incomeTotal
    setIncomeTotal((incomeFirst || 0) + (incomeSecond || 0));
  }, [watch("incomeFirst"), watch("incomeSecond")]);

  //Outcomes
  //Mesašné splátky úverov
  React.useEffect(() => {
    const repaymentFirst = parseFloat(watch("monthlyRepaymentFirst", 0));
    const repaymentSecond = parseFloat(watch("monthlyRepaymentSecond", 0));
    setMonthlyRepaymentTotal((repaymentFirst || 0) + (repaymentSecond || 0));
  }, [watch("monthlyRepaymentFirst"), watch("monthlyRepaymentSecond")]);

  React.useEffect(() => {
    const balanceFirst = parseFloat(watch("currentLoanBalanceFirst", 0));
    const balanceSecond = parseFloat(watch("currentLoanBalanceSecond", 0));
    setCurrentLoanBalanceTotal((balanceFirst || 0) + (balanceSecond || 0));
  }, [watch("currentLoanBalanceFirst"), watch("currentLoanBalanceSecond")]);

  React.useEffect(() => {
    const overdraftFirst = parseFloat(watch("allowedOverdraftFirst", 0));
    const overdraftSecond = parseFloat(watch("allowedOverdraftSecond", 0));
    setAllowedOverdraftTotal((overdraftFirst || 0) + (overdraftSecond || 0));
  }, [watch("allowedOverdraftFirst"), watch("allowedOverdraftSecond")]);

  //Watch changes for calculating DTI and DTIOld
  React.useEffect(() => {
    const dtiNewTotal = dtiNewFirst + dtiNewSecond;
    const dtiOldTotal = dtiOldFirst + dtiOldSecond;
    const overdraftPercent = (allowedOverdraftTotal * 20) / 100;

    const newDtiNewResult =
      dtiNewTotal - currentLoanBalanceTotal - overdraftPercent;
    const newDtiOldResult =
      dtiOldTotal - currentLoanBalanceTotal - overdraftPercent;

    setDtiNewResult(newDtiNewResult);
    setDtiOldResult(newDtiOldResult);
  }, [
    dtiNewFirst,
    dtiNewSecond,
    dtiOldFirst,
    dtiOldSecond,
    currentLoanBalanceTotal,
    allowedOverdraftTotal,
  ]);

  const livingWageFirst = 273.99;
  const livingWageSecond = secondFieldsVisible ? 273.99 : 191.14;
  const kidsFirstValue = watch("kidsFirst");
  const kidsSecondValue = watch("kidsSecond");

  //Watch changes in kids fields
  React.useEffect(() => {
    const livingWageAdults =
      livingWageFirst + (toggleValue ? livingWageSecond : 0);

    const kidsSecondValue = toggleValue ? watch("kidsSecond") : null;

    const kidsFirstPrice = getPriceFromOptions(optionsKids, kidsFirstValue);
    const kidsSecondPrice = getPriceFromOptions(optionsKids, kidsSecondValue);

    const newLivingWageTotal =
      livingWageAdults + kidsFirstPrice + kidsSecondPrice;
    setLivingWageTotal(newLivingWageTotal);
  }, [
    watch("kidsFirst"),
    watch("kidsSecond"),
    toggleValue,
    optionsKids,
    livingWageFirst,
    livingWageSecond,
  ]);

  //Max Splátka
  React.useEffect(() => {
    const incomeTotal = (incomeFirst || 0) + (incomeSecond || 0);
    const maxSplatkaValue =
      0.6 * (incomeTotal - livingWageTotal) -
      monthlyRepaymentTotal -
      0.03 * allowedOverdraftTotal;

    setMaxSplatka(maxSplatkaValue);
  }, [
    incomeFirst,
    incomeSecond,
    livingWageTotal,
    monthlyRepaymentTotal,
    allowedOverdraftTotal,
  ]);

  //Watch changes for PV
  React.useEffect(() => {
    const ageCalcOne = ageDifferenceTo70 <= 30 ? ageDifferenceTo70 : 30;
    const ageCalcTwo = ageDifferenceTo65 <= 30 ? ageDifferenceTo65 : 30;

    const newPvNew = PV(
      (urokovaSadzba + 2) / 1200,
      ageCalcOne * 12,
      -maxSplatka,
      0
    );
    const newPvOld = PV(
      (urokovaSadzba + 2) / 1200,
      ageCalcTwo * 12,
      -maxSplatka,
      0
    );
    //setUrokovaSadzba(urokovaSadzba);
    setAgeCalcOne(ageCalcOne);
    setAgeCalcTwo(ageCalcTwo);
    setPvNew(newPvNew);
    setPvOld(newPvOld);
  }, [
    //watch("incomeFirst"),
    //watch("incomeSecond"),
    incomeFirst,
    incomeSecond,
    olderUserAge,
    maxSplatka,
    ageCalcOne,
    ageCalcTwo,
  ]);

  //Watch changes for Compare DTI and PV
  React.useEffect(() => {
    const newCompareNew = Math.min(dtiNewResult, pvNew);
    const newCompareOld = Math.min(dtiOldResult, pvOld);

    setCompareNew(newCompareNew);
    setCompareOld(newCompareOld);

    const newCompareTotal = Math.max(newCompareNew, newCompareOld);
    setCompareTotal(newCompareTotal);
  }, [dtiNewResult, dtiOldResult, pvNew, pvOld]);

  const calculateDTINew = (income, coefficient) => {
    return (income || 0) * 12 * coefficient;
  };
  const calculateDTIOld = (income) => {
    const coefficientOld = 8;
    return (income || 0) * 12 * coefficientOld;
  };

  const uverResultF = Intl.NumberFormat("sk-SK", {
    style: "currency",
    currency: "EUR",
  }).format(compareTotal);

  const uverNone = "Nepostačujúci príjem";

  const [ageResult, setAgeResult] = useState(0);
  React.useEffect(() => {
    const ageResult = compareNew < compareOld ? ageCalcTwo : ageCalcOne;
    const newPmtt = PMT(
      urokovaSadzba / 1200,
      ageResult * 12,
      -compareTotal,
      0,
      0
    );
    setPmtt(newPmtt);
    setAgeResult(ageResult);
  }, [compareNew, compareOld, ageCalcOne, ageCalcTwo, compareTotal]);

  const pmttF = Intl.NumberFormat("sk-SK", {
    style: "currency",
    currency: "EUR",
  }).format(pmtt);

  //Display results
  function UverResultText() {
    if (incomeTotal && kidsFirstValue && compareTotal > "4500") {
      return uverResultF;
    } else {
      return <span>0 €</span>;
    }
  }
  function SplatkaResultText() {
    if (incomeTotal && kidsFirstValue && compareTotal > "4500") {
      return pmttF;
    } else {
      return <span>0 €</span>;
    }
  }

  //Doplnkové funkcie
  function ResetButton() {
    return (
      <Link href={href}>
        <Button
          leftIcon={<MdRefresh />}
          colorScheme="gray"
          variant={"outline"}
          size="sm"
        >
          Nový výpočet
        </Button>
      </Link>
    );
  }
  function ButtonVypocet() {
    return (
      <Link href="/vypocitaj-si/vysku-splatky">
        <Button
          leftIcon={<MdCalculate />}
          colorScheme="gray"
          variant={"outline"}
          size="sm"
        >
          Výpočet splátky
        </Button>
      </Link>
    );
  }
  const [alert, setAlert] = useState(null);

  React.useEffect(() => {
    if (alert) {
      const timer = setTimeout(() => {
        setAlert(null);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [alert]);
  
  const [ponuka, setPonuka] = useBoolean();
  function PonukaButton() {
    return (
      <>
        {incomeTotal && kidsFirstValue && compareTotal > "4500" ? (
          <Button
            my={5}
            colorScheme="primary"
            size="lg"
            height="96px"
            width="100%"
            fontSize="28px"
            onClick={setPonuka.toggle}
          >
            Chcem ponuku
          </Button>
        ) : (
          <Button
            my={5}
            colorScheme="primary"
            size="lg"
            height="96px"
            width="100%"
            fontSize="28px"
            onClick={setPonuka.toggle}
            disabled
          >
            Chcem ponuku
          </Button>
        )}

        {ponuka ? (
          <>
            <Box bg={"primary.50"} p={4} borderRadius={"6"}>
              <SimpleGrid
                columns={[1, null, 3]}
                spacing={5}
                textAlign={"center"}
              >
                <Box>
                  <Box mb={4} color="green.500">
                    Meno *
                  </Box>
                  <FormControl id="name" mb={4} isRequired>
                    <Input
                      type="text"
                      name="name"
                      label="Meno a priezvisko"
                      bg={"white"}
                      {...register("name", { required: true })}
                    />
                  </FormControl>
                </Box>

                <Box>
                  <Box mb={4} color="green.500">
                    E-mail *
                  </Box>
                  <FormControl id="email" mb={4} isRequired>
                    <Input
                      type="email"
                      name="email"
                      label="E-mail"
                      bg={"white"}
                      {...register("email", { required: true })}
                    />
                  </FormControl>
                </Box>

                <Box>
                  <Box mb={4} color="green.500">
                    Telefón *
                  </Box>
                  <FormControl id="phone" mb={4} isRequired>
                    <Input
                      type="number"
                      name="telefon"
                      label="Telefón"
                      bg={"white"}
                      {...register("phone", { required: true })}
                    />
                  </FormControl>
                </Box>
              </SimpleGrid>
              <FormControl id="notice" mb={4}>
                <Textarea
                  placeholder="Poznámka"
                  size="sm"
                  bgColor={"white"}
                  {...register("notice")}
                />
              </FormControl>

              <FormControl id="consent" isRequired>
                <Checkbox
                  borderColor={"green"}
                  colorScheme={"white"}
                  {...register("consent", { required: true })}
                >
                  Súhlasím so spracovaním{" "}
                  <Link
                    href="/ochrana-osobnych-udajov"
                    target={"_blank"}
                    style={{ textDecoration: "underline" }}
                  >
                    osobných údajov
                  </Link>
                </Checkbox>
              </FormControl>
            </Box>
            <Box mt="4">
              {alert && (
                <Alert status={alert.status}>
                  <AlertIcon />
                  {alert.title}
                </Alert>
              )}
            </Box>
            <Center>
              <Button
                leftIcon={<MdSend />}
                my={8}
                colorScheme="secondary"
                size="lg"
                type="submit"
                fontSize={"2xl"}
                p={8}
                isLoading={isSubmitting}
                loadingText="Odosielam"
              >
                Odoslať
              </Button>
            </Center>
          </>
        ) : null}
      </>
    );
  }

  const production = 1;
  function Debug() {
    if (production === 0) {
      return (
        <Box
          position={"fixed"}
          top={100}
          left={0}
          pl={2}
          bgColor={"white"}
          zIndex={1000}
        >
          <Text mt={4}>Age User 1: {ageFirst}</Text>
          <Text mt={4}>Age User 2: {ageSecond}</Text>
          <Text mt={4}>Income User 1: {incomeFirst}</Text>
          <Text mt={4}>Income User 2: {incomeSecond}</Text>
          <Text mt={4}>Income Total: {incomeTotal}</Text>

          <Text mt={4}>Coefficient User 1: {coefficient.toFixed(2)}</Text>
          <Text mt={4}>Coefficient User 2: {coefficientSecond.toFixed(2)}</Text>

          <Text mt={4}>DTI User 1: {dtiNewFirst.toFixed(2)}</Text>
          <Text mt={4}>DTI User 2: {dtiNewSecond.toFixed(2)}</Text>
          <Text mt={4}>DTI New Total: {dtiNewResult.toFixed(2)}</Text>
          <Text mt={4}>DTI Old Total: {dtiOldResult.toFixed(2)}</Text>
          <Text mt={4}>DTI Old User 1: {dtiOldFirst.toFixed(2)}</Text>
          <Text mt={4}>DTI Old User 2: {dtiOldSecond.toFixed(2)}</Text>
          <Text mt={4}>
            Monthly Repayment Total: {monthlyRepaymentTotal.toFixed(2)}
          </Text>
          <Text mt={4}>
            Current Loan Balance Total: {currentLoanBalanceTotal.toFixed(2)}
          </Text>
          <Text mt={4}>
            Allowed Overdraft Total: {allowedOverdraftTotal.toFixed(2)}
          </Text>
          <Text mt={4}>Older User Age: {olderUserAge}</Text>
          <Text mt={4}>Difference to 70: {ageDifferenceTo70}</Text>
          <Text mt={4}>Difference to 65: {ageDifferenceTo65}</Text>
          <Text mt={4}>AgeCalcOne: {ageCalcOne}</Text>
          <Text mt={4}>AgeCalcTwo: {ageCalcTwo}</Text>

          <Text mt={4}>Toggle: {toggleValue}</Text>
          <Text mt={4}>Max splátka: {maxSplatka}</Text>
          <Text mt={4}>DTI Result: {dtiNewResult.toFixed(2)}</Text>
          <Text mt={4}>DTI Old Result: {dtiOldResult.toFixed(2)}</Text>
          <Text mt={4}>Living Wage Total: {livingWageTotal.toFixed(2)}</Text>
          <Text mt={4}>PV New: {pvNew}</Text>
          <Text mt={4}>PV Old: {pvOld}</Text>
          <Text mt={4}>urokovaSadzba: {urokovaSadzba}</Text>
          <Box>
            {compareTotal > 0 ? (
              <Text>Maximálna výška úveru: {uverResultF}</Text>
            ) : (
              <Text>{uverNone}</Text>
            )}
          </Box>
          <Text>PMTT: {pmtt}</Text>
        </Box>
      );
    }
  }



  async function sendEmail(data) {
    return emailjs.send(
      "service_9wra7fs",
      "template_vt2lspp",
      data,
      "HTfSlZtu5T8h_NdZZ"
    );
  }

  const repaymentFirst = parseFloat(watch("monthlyRepaymentFirst", 0));
  const repaymentSecond = parseFloat(watch("monthlyRepaymentSecond", 0));

  const loanBalanceFirst = parseFloat(watch("currentLoanBalanceFirst", 0));
  const loanBalanceSecond = parseFloat(watch("currentLoanBalanceSecond", 0));

  const overdraftFirst = parseFloat(watch("allowedOverdraftFirst", 0));
  const overdraftSecond = parseFloat(watch("allowedOverdraftSecond", 0));

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    console.log("Form submitted with data:", data);
    try {
      // Add uverResultF to the form data

      data.uverResultF = uverResultF;
      data.incomeTotal = incomeTotal;
      data.loanBalanceFirst = loanBalanceFirst;
      data.loanBalanceSecond = loanBalanceSecond;
      data.overdraftFirst = overdraftFirst;
      data.overdraftSecond = overdraftSecond;
      data.ageResult = ageResult;
      data.maxSplatka = maxSplatka;
      data.repaymentFirst = repaymentFirst;
      data.repaymentSecond = repaymentSecond;
      data.livingWageTotal = livingWageTotal;
      data.ageCalcOne = ageCalcOne;
      data.ageCalcTwo = ageCalcTwo;
      data.pmttF = pmttF;

      // Call your sendEmail function with EmailJS here
      await sendEmail(data);

      // Update email status and show the Alert
      setAlert({
        status: "success",
        title: "Ďakujeme! Správa bola odoslaná. Budeme vás čoskoro kontaktovať",
      });
      reset();
      setIsSubmitting(false);
      setToggleValue(false);
      setSecondFieldsVisible(false);
      //setPonuka(false);
      
    } catch (error) {
      // Update email status and show the Alert
      setAlert({
        status: "error",
        title: "Pri odosielaní nastala chyba. Skúste to znovu prosím",
      });
      setIsSubmitting(false);
      console.log("Failed to send email", error);
      
    }
  };

  return (
    <section>
      <SEO
        metaTitle="Hypokalkulačka | Splátky úveru - MaxiHypo"
        metaDesc="Hypokalkulačka - online výpočet hypotéky podľa príjmu. Rýchlo a jednoducho si vypočítajte výšku hypotéky a mesačnej splátky."
        name="Maxihypo.sk"
        type="website"
        author="maxihypo"
      />
      <Debug />
      <Heading as="h1" textAlign={"center"} my={"16"}>
        Vypočítaj si výšku úveru
      </Heading>
      <Box
        maxW={"960"}
        mx={"auto"}
        my={16}
        p={{ base: 4, sm: 4, md: 8 }}
        borderColor="secondary.100"
        borderWidth={{ sm: 0, md: 0, lg: 1 }}
        borderRadius={"5px"}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Center mb={8}>
            <span>
              <Image
                src={jedenImg}
                alt="Jeden"
                width="100%"
                height="100%"
                style={{
                  maxWidth: "100px",
                  maxHeight: "100px",
                  filter: toggleValue ? "grayscale(100%)" : "",
                }}
              />
            </span>
            <span
              style={{
                color: "#144D29",
                display: toggleValue ? "none" : "block",
                alignContent: "center",
              }}
            >
              &nbsp;
              <b>Jeden žiadateľ</b>
              &nbsp;
            </span>
            <span
              style={{
                alignContent: "center",
                display: toggleValue ? "block" : "none",
              }}
            >
              &nbsp;Jeden žiadateľ&nbsp;
            </span>
            <Switch
              id="toggle"
              colorScheme="secondary"
              onChange={handleToggleChange}
            />
            <span
              style={{
                color: "#144D29",
                display: toggleValue ? "block" : "none",
                textAlign: "center",
              }}
            >
              &nbsp;<b>Dvaja žiadatelia</b>
            </span>
            <span
              style={{
                textAlign: "center",
                display: toggleValue ? "none" : "block",
              }}
            >
              &nbsp;Dvaja žiadatelia
            </span>
            <span>
              <Image
                src={dvajaImg}
                alt="Dvaja"
                width="100%"
                height="100%"
                style={{
                  maxWidth: "100px",
                  maxHeight: "100px",
                  filter: toggleValue ? "" : "grayscale(100%)",
                }}
              />
            </span>
          </Center>

          <SimpleGrid
            columns={{
              base: toggleValue ? 1 : 1,
              sm: toggleValue ? 2 : 1,
              md: toggleValue ? 2 : 1,
              lg: toggleValue ? 2 : 1,
            }}
            spacing={5}
            mx={"auto"}
            maxW={toggleValue ? null : "600px"}
          >
            <Box>
              {/*first column*/}
              <Center py={4} color="secondary.600" bg={"secondary.50"}>
                <Text p={1}>
                  <b>Údaje žiadateľa</b>
                </Text>
              </Center>
              <FormControl
                mx="auto"
                my={4}
                variant="floating"
                id="ageFirst"
                isRequired
              >
                <Select
                  name="ageFirst"
                  placeholder="Vek"
                  borderColor={"secondary.100"}
                  _hover={{
                    borderColor: "secondary.500",
                  }}
                  {...register("ageFirst", { required: true })}
                >
                  {optionsAge.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl id="incomeFirst" isRequired mx="auto" mb={4}>
                <Input
                  type="number"
                  name="incomeFirst"
                  label="Mesačný príjem"
                  placeholder="Mesačný príjem"
                  className="yellow"
                  focusBorderColor={"secondary.500"}
                  isRequired
                  {...register("incomeFirst", { required: true })}
                />
              </FormControl>

              <FormControl
                id="kidsFirst"
                mx="auto"
                variant="floating"
                isRequired
              >
                <Select
                  className="yellow"
                  placeholder="Počet detí"
                  label="Počet detí"
                  name="kidsFirst"
                  isRequired
                  defaultValue=""
                  {...register("kidsFirst", { required: true })}
                >
                  {optionsKids.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <Box mt={4} mb={2} color="secondary.500">
                &nbsp;Mesačná splátka úverov&nbsp;
                <Tooltip
                  label="Sčítaj a zadaj mesačnú splátku všetkých tvojich úverov (splátky úverov v bankách, aj splátky za kúpu tovarov na splátky - napr. kosačka na splátky a pod.). Splátku úveru, kt. chceš vyplatiť tu neuvádzaj."
                  fontSize="md"
                >
                  <QuestionIcon />
                </Tooltip>
              </Box>
              <FormControl id="monthlyRepaymentFirst">
                <Input
                  type="number"
                  name="monthlyRepaymentFirst"
                  placeholder="€"
                  label="Mesačná splátka úverov"
                  className="yellow"
                  focusBorderColor={"secondary.500"}
                  {...register("monthlyRepaymentFirst")}
                />
              </FormControl>
              <Box mt={4} mb={2} color="secondary.500">
                &nbsp;Aktuálny zostatok úverov&nbsp;
                <Tooltip
                  label="Sčítaj a zadaj aktuálny zostatok všetkých tvojich úverov (úverov v bankách, aj úverov na kúpu tovarov na splátky - napr. kosačka na splátky a pod.). Zostatok úveru, kt. chceš vyplatiť tu neuvádzaj."
                  fontSize="md"
                >
                  <QuestionIcon />
                </Tooltip>
              </Box>
              <FormControl id="currentLoanBalanceFirst">
                <Input
                  type="number"
                  name="currentLoanBalanceFirst"
                  placeholder="€"
                  label="Mesačná splátka úverov"
                  className="yellow"
                  focusBorderColor={"secondary.500"}
                  {...register("currentLoanBalanceFirst")}
                />
              </FormControl>

              <Box mt={4} mb={2} color="secondary.500">
                &nbsp;Výška limitu povoleného prečerpania&nbsp;
                <Tooltip
                  alignContent={"right"}
                  label="Sčítaj a zadaj limity povolených prečerpaní (ak ich máš) na všetkých svojich účtoch a na všetkých kreditných kartách (ak máš). Pozn. kreditná karta je úverová karta, nie karta od tvojho osobného účtu."
                  fontSize="md"
                >
                  <QuestionIcon />
                </Tooltip>
              </Box>
              <FormControl id="allowedOverdraftFirst">
                <Input
                  type="number"
                  name="allowedOverdraftFirst"
                  placeholder="€"
                  label="Mesačná splátka úverov"
                  className="yellow"
                  focusBorderColor={"secondary.500"}
                  {...register("allowedOverdraftFirst")}
                />
              </FormControl>
            </Box>
            <Box id="second">
              {/*Switchold*/}

              <Box style={{ display: toggleValue ? "block" : "none" }}>
                <Box>
                  <Center py={4} color="primary.500" bg={"primary.100"}>
                    <Text p={1}>
                      <b>Údaje druhého žiadateľa</b>
                    </Text>
                  </Center>
                  <FormControl
                    mx="auto"
                    my={4}
                    variant="floating"
                    id="ageSecond"
                  >
                    <Select
                      name="ageSecond"
                      placeholder="Vek"
                      _hover={{
                        borderColor: "primary.500",
                      }}
                      {...register("ageSecond")}
                    >
                      {optionsAge.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl id="incomeSecond" mx="auto" mb={4}>
                    <Input
                      type="number"
                      name="incomeSecond"
                      label="Mesačný príjem"
                      placeholder="Mesačný príjem"
                      focusBorderColor={"primary.500"}
                      _hover={{
                        borderColor: "primary.500",
                      }}
                      {...register("incomeSecond")}
                    />
                  </FormControl>

                  <Center>
                    <span
                      style={{
                        display: secondFieldsVisible ? "block" : "none",
                        color: "primary.500",
                      }}
                    >
                      Žijete s druhým žiadateľom v spoločnej domácnosti?&nbsp;
                    </span>
                    <span
                      color="primary.500"
                      style={{
                        display: secondFieldsVisible ? "none" : "block",
                      }}
                    >
                      Žijete s druhým žiadateľom v spoločnej domácnosti?&nbsp;
                    </span>
                    <span
                      style={{
                        color: "#144D29",
                        display: secondFieldsVisible ? "none" : "block",
                      }}
                    >
                      &nbsp;
                      <b>Áno</b>
                      &nbsp;
                    </span>
                    <span
                      style={{
                        display: secondFieldsVisible ? "block" : "none",
                      }}
                    >
                      &nbsp;Áno&nbsp;
                    </span>
                    <Switch
                      colorScheme="primary"
                      id="secondFieldsToggle"
                      isChecked={secondFieldsVisible}
                      onChange={(e) => setSecondFieldsVisible(e.target.checked)}
                    />
                    <span
                      style={{
                        color: "#144D29",
                        display: secondFieldsVisible ? "block" : "none",
                      }}
                    >
                      &nbsp;<b>Nie</b>
                    </span>
                    <span
                      style={{
                        display: secondFieldsVisible ? "none" : "block",
                      }}
                    >
                      &nbsp;Nie
                    </span>
                  </Center>

                  {secondFieldsVisible && (
                    <>
                      <FormControl id="kidsSecond">
                        <Select
                          placeholder="Počet detí"
                          {...register("kidsSecond")}
                        >
                          {optionsKids.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Select>
                      </FormControl>

                      <Box mt={4} mb={2} color="primary.500">
                        &nbsp;Mesačná splátka úverov&nbsp;
                        <Tooltip
                          label="Sčítaj a zadaj mesačnú splátku všetkých tvojich úverov (splátky úverov v bankách, aj splátky za kúpu tovarov na splátky - napr. kosačka na splátky a pod.). Splátku úveru, kt. chceš vyplatiť tu neuvádzaj."
                          fontSize="md"
                        >
                          <QuestionIcon />
                        </Tooltip>
                      </Box>
                      <FormControl id="monthlyRepaymentSecond">
                        <Input
                          type="number"
                          name="monthlyRepaymentSecond"
                          placeholder="€"
                          label="Mesačná splátka úverov"
                          focusBorderColor={"primary.500"}
                          _hover={{
                            borderColor: "primary.500",
                          }}
                          {...register("monthlyRepaymentSecond")}
                        />
                      </FormControl>

                      <Box mt={4} mb={2} color="primary.500">
                        &nbsp;Aktuálny zostatok úverov&nbsp;
                        <Tooltip
                          label="Sčítaj a zadaj aktuálny zostatok všetkých tvojich úverov (úverov v bankách, aj úverov na kúpu tovarov na splátky - napr. kosačka na splátky a pod.). Zostatok úveru, kt. chceš vyplatiť tu neuvádzaj."
                          fontSize="md"
                        >
                          <QuestionIcon />
                        </Tooltip>
                      </Box>
                      <FormControl id="currentLoanBalanceSecond">
                        <Input
                          type="number"
                          name="currentLoanBalanceSecond"
                          placeholder="€"
                          label="Aktuálny zostatok úverov"
                          focusBorderColor={"primary.500"}
                          _hover={{
                            borderColor: "primary.500",
                          }}
                          {...register("currentLoanBalanceSecond")}
                        />
                      </FormControl>

                      <Box mt={4} mb={2} color="primary.500">
                        &nbsp;Výška limitu povoleného prečerpania&nbsp;
                        <Tooltip
                          alignContent={"right"}
                          label="Sčítaj a zadaj limity povolených prečerpaní (ak ich máš) na všetkých tvojich účtoch a na všetkých kreditných kartách (ak máš). Pozn. kreditná karta je úverová karta, nie karta od tvojho osobného účtu."
                          fontSize="md"
                        >
                          <QuestionIcon />
                        </Tooltip>
                      </Box>
                      <FormControl id="allowedOverdraftSecond">
                        <Input
                          type="number"
                          name="allowedOverdraftSecond"
                          placeholder="€"
                          label="Výška limitu povoleného prečerpania"
                          focusBorderColor={"primary.500"}
                          _hover={{
                            borderColor: "primary.500",
                          }}
                          {...register("allowedOverdraftSecond")}
                        />
                      </FormControl>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </SimpleGrid>

          {/* Výsledky */}

          <Box>
            <SimpleGrid
              columns={[1, null, 3]}
              spacing={5}
              textAlign={"center"}
              mt={8}
            >
              <Box bg="secondary.500" px="18px" py="18px">
                <Text fontSize="2xl" color="white">
                  Max. výška úveru
                </Text>
                <Text fontSize="4xl" color="white">
                  <UverResultText />
                </Text>
                <Text fontSize="l" color="white">
                  Orientačná výška úveru
                </Text>
              </Box>
              <Box bg={"secondary.500"} px="18px" py="18px">
                <Text fontSize="2xl" color="white">
                  Splátka úveru
                </Text>
                <Text fontSize="4xl" color="white">
                  <SplatkaResultText />
                </Text>
                <Text fontSize="l" color="white">
                  po dobu {ageResult} rokov
                </Text>
              </Box>
              <Box bg="secondary.500" p="18px">
                <Text fontSize="2xl" color="white">
                  Úroková sadzba
                </Text>
                <Text fontSize="4xl" color="white">
                  {urokovaSadzba}% p.a.
                </Text>
                <Text fontSize="l" color="white">
                  Orientačná úroková sadzba
                </Text>
              </Box>
            </SimpleGrid>
          </Box>

          <PonukaButton />

          <Flex
            direction={{ base: "column-reverse", sm: "row" }}
            alignContent={{ sm: "flex-end" }}
            justifyContent={"space-around"}
          >
            <Box mb={4}>
              <ButtonVypocet />
            </Box>
            <Spacer />
            <Box mb={4}>
              <ResetButton />
            </Box>
          </Flex>
        </form>
      </Box>
    </section>
  );
};

export default Hypoteka;
