import {
  Stack,
  Flex,
  Icon,
  Heading,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  MdFacebook,
} from "react-icons/md";
import backgroundimg from "../static/MaxiHypoHero.webp";

export default function Hero() {
  return (
    <section>
      <Flex
        w={"full"}
        h={{ base: "80vh", sm: "60vh" }}
        bg={"primary.500"}
        backgroundImage={backgroundimg}
        backgroundSize={"cover"}
        backgroundPosition={"center center"}
      >
        <VStack
          w={"full"}
          justify={"center"}
          px={{ base: 4, sm: 4, md: 8 }}
          bgGradient={"linear(to-r, blackAlpha.600, transparent)"}
        >
          <Stack maxW={"2xl"} align={"flex-start"} spacing={6}>
            <Heading
              as={"h1"}
              color={"white"}
              fontWeight={700}
              lineHeight={1.2}
              textTransform={"upperCase"}
              fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
            >
              Výpočet hypotéky
            </Heading>
            <Heading
              as={"h2"}
              color={"secondary.500"}
              fontWeight={700}
              lineHeight={1}
              textTransform={"lowerCase"}
              fontSize={useBreakpointValue({
                base: "xl",
                sm: "2xl",
                md: "3xl",
              })}
              textShadow="1px 1px 0 #00000099"
              my={0}
            >
              Online hypokalkulačka
              <br />
              bezplatné poradenstvo a sprostredkovanie hypotéky
            </Heading>
            <a
              href="https://www.facebook.com/maxihypo"
              target={"blank"}
              rel={"nopener"}
            >
              <Icon
                as={MdFacebook}
                boxSize={8}
                color={"secondary.500"}
                border="0"
                borderRadius="200px"
              />
            </a>
          </Stack>
        </VStack>
      </Flex>
    </section>
  );
}
