import React from 'react'
import { Alert, AlertDescription, Box, Icon, Heading, Text, SimpleGrid  } from "@chakra-ui/react";
import {
  MdArrowDownward
} from "react-icons/md";
import SEO from './SEO';

function Akotochodi() {
  return (
    <article>
      <SEO
        metaTitle="Ako vybaviť hypotéku … - MaxiHypo"
        metaDesc="Postup, ako vybavíte hypotéku. Od prípravy podkladov, spracovania žiadosti, podpisu zmlúv, poistenia, vinkulácie až po čerpanie hypotéky."
        name="Maxihypo.sk"
        type="article"
        author="maxihypo"
      />
      <Box boxShadow="inner" bgColor={"secondary.100"} className="title">
        <Heading
          as="h1"
          py="40px"
          px="20px"
          maxW={1260}
          mx="auto"
          textTransform="uppercase"
          color={"primary.900"}
        >
          Ako to chodí ...
        </Heading>
      </Box>
      <Box maxW={"960"} mx="auto" p="20px">
        <Heading as="h2" textTransform="uppercase" fontSize="2xl">
          Ako vybavíš hypotéku?
        </Heading>
        <Text as={"ul"} py="20px" ml="20px">
          <li>
            Ak už presne vieš, na čo financie potrebuješ, ideálne je zistiť si,
            koľko Ti Tvoj rodinný rozpočet umožní požičať si. Predbežnú
            (orientačnú) výšku úveru zistíš na maxihypo.sk, alebo Ti ju
            prepočíta finančný agent, príp. poradca v banke. Presnú výšku úveru
            sa však dozvieš až po podaní a vyhodnotení žiadosti v banke. K
            žiadosti ešte bude nutné predložiť niekoľko dokladov a spresniť
            informácie napr. o splatnosti, výdavkoch a pod..
          </li>
          <li>
            Či pôjdeš priamo do banky, alebo využiješ služby finančného agenta,
            je len na Tebe. Rozdiel je v čase a v možnostiach. Kým v banke
            získaš len ponuku konkrétnej banky, finančný agent Ti umožní vybrať
            si ponuku z viacerých bánk. Zároveň Ti ušetrí čas a bude Ťa
            sprevádzať celým procesom.
          </li>
        </Text>
        <Alert
          status="warning"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertDescription maxWidth="md" color={"primary.900"}>
            Ak by si sa predsa len rozhodol navštíviť priamo banku, takto
            približne to bude prebiehať:
          </AlertDescription>
          <Icon
            as={MdArrowDownward}
            boxSize="40px"
            color={"primary.900"}
            mr={0}
            mt={8}
          />
        </Alert>
      </Box>
      <SimpleGrid
        columns={{ base: 1, md: 3, lg: 3 }}
        gap={5}
        maxW="960px"
        mx="auto"
        align={"center"}
        p="20px"
      >
        <Box p={4}>
          <Text as="h3" textTransform="uppercase" fontWeight="bold" mb="20px">
            1. Úvodné stretnutie a podpis žiadosti
          </Text>
          <Text align="justify">
            K podpisu žiadosti môže dôjsť aj na prvom stretnutí. Záleží to napr.
            od dokladov, ktoré k žiadosti potrebuješ, od typu Tvojho pracovného
            pomeru, od banky atď. Podpis žiadosti Ťa ešte k ničomu nezaväzuje.
            Banka ju prehodnotí a oznámi Ti, či (a za akých podmienok) Ti úver
            poskytne (príp. neposkytne). K žiadosti je potrebné predložiť
            dokumenty podľa účelu úveru napr. návrh kúpnej zmluvy a pod. Po
            schválení žiadosti sa ešte stále môžeš rozhodnúť, či s podmienkami
            súhlasíš a úver zoberieš alebo nie. Ak nie, požiadaš o storno
            žiadosti. Ak áno, dohodneš si deň podpisu zmlúv a príp. ďalšie
            podmienky, ktoré budú k uzatvoreniu zmlúv potrebné.
          </Text>
        </Box>
        <Box p={4}>
          <Text as="h3" textTransform="uppercase" fontWeight="bold" mb="20px">
            2. Znalecký posudok
          </Text>
          <Text align="justify">
            Je prílohou k žiadosti o úver. Pred samotným oslovením znalca je ale
            dobré najskôr navštíviť{" "}
            <a href="https://www.maxihypo.sk">maxihypo.sk</a> resp. finančného
            agenta, alebo ísť priamo do banky, aby Ti poskytli základné
            informácie napr. o akceptovaní Tvojho príjmu alebo možnostiach
            stanovenia interného ohodnotenia nehnuteľnosti (aby si zbytočne
            nevyhodil peniaze na znalecký posudok, ak by Ti banka úver
            neposkytla). Prípadne tam získaš kontakty na znalcov, s ktorými
            banka spolupracuje.
          </Text>
        </Box>
        <Box p={4}>
          <Text as="h3" textTransform="uppercase" fontWeight="bold" mb="20px">
            3. Podpis zmlúv
          </Text>
          <Text align="justify">
            Ak máš úver schválený a rozhodol si sa, že si ho zoberieš, okrem
            uzatvorenia úverovej zmluvy budeš podpisovať aj zmluvu o zriadení
            záložného práva k nehnuteľnosti (zabezpečenie úveru).
          </Text>
        </Box>
        <Box p={4}>
          <Text as="h3" textTransform="uppercase" fontWeight="bold" mb="20px">
            4. Poistenie nehnuteľnosti a vinkulácia
          </Text>
          <Text align="justify">
            Nehnuteľnosť, ktorou je úver zabezpečený je potrebné poistiť a túto
            poistku následne vinkulovať v prospech banky. Toto poistenie vrátane
            vinkulácie Ti vie obvykle zabezpečiť aj finančný agent príp. banka.
          </Text>
        </Box>
        <Box p={4}>
          <Text as="h3" textTransform="uppercase" fontWeight="bold" mb="20px">
            5. Návšteva katastra nehnuteľností
          </Text>
          <Text align="justify">
            Je posledným krokom pred čerpaním finančných prostriedkov z úveru.
            Odnesieš zmluvu o zriadení záložného práva z banky na kataster,
            ktorý Ti jej prevzatie potvrdí. Toto potvrdenie následne prinesieš
            do banky. Niektoré banky Ti však túto povinnosť zabezpečia a
            „navštívia“ kataster za Teba.
          </Text>
        </Box>
        <Box p={4}>
          <Text as="h3" textTransform="uppercase" fontWeight="bold" mb="20px">
            6. Čerpanie úveru
          </Text>
          <Text align="justify">
            Po splnení všetkých podmienok čerpania uvedených v úverovej zmluve
            (predloženie potvrdenia z katastra o odovzdaní záložných zmlúv,
            predloženie poistnej zmluvy k nehnuteľnosti a vinkulácie a pod.),
            banka prevedie finančné prostriedky na účet napr. predávajúceho (pri
            kúpe nehnuteľnosti) alebo na Tvoj osobný účet alebo ich pošle do
            banky v ktorej splácaš „starý“ úver (záleží od účelu úveru).
          </Text>
        </Box>
      </SimpleGrid>
      <Alert
        status="warning"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        py={8}
      >
        <AlertDescription
          maxWidth="2xl"
          fontWeight="bold"
          fontSize="xl"
          color={"green.900"}
          textTransform="uppercase"
          lineHeight={8}
          textAlign={"justify"}
        >
          Je toho ešte veľa s čím sa môžeš stretnúť a všetko sa to snáď ani
          popísať nedá. Ale ničím z toho sa zaoberať nemusíš, ak sa rozhodneš
          využiť služby maxihypo.sk.
        </AlertDescription>
      </Alert>
    </article>
  );
}

export default Akotochodi
