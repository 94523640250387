import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Uvod from "./components/Uvod";
import Uver from "./components/Uver";
import Splatky from "./components/Splatky";
import Omne from "./components/Omne";
import Akotochodi from "./components/Akotochodi";
import Zakolko from "./components/Zakolko";
import Kontakt from "./components/Kontakt";
import Ochrana from "./components/OchranaOsobnychUdajov";
/* import Hypoteka from "./components/Uvertest";
import MyComponent from "./components/Test";
import LoanCalculator from "./components/Pocitajuver";
import Applikacia from "./components/Newtest"; */

function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Uvod />} />

        <Route path="vypocitaj-si/vysku-uveru" element={<Uver />} />
        <Route path="vypocitaj-si/vysku-splatky" element={<Splatky />} />
        <Route path="nieco-o-mne" element={<Omne />} />
        <Route path="ako-to-chodi" element={<Akotochodi />} />
        <Route path="za-kolko" element={<Zakolko />} />
        <Route path="kontakt" element={<Kontakt />} />
        <Route path="ochrana-osobnych-udajov" element={<Ochrana />} />
        {/* <Route path="test" element={<Hypoteka />} />
        <Route path="test2" element={<MyComponent />} />
        <Route path="testnew" element={<LoanCalculator />} />
        <Route path="app" element={<Applikacia />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
