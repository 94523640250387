import { extendTheme } from "@chakra-ui/react";
//import { inputTheme } from "../components/input.jsx";

const colors = {
  primary: {
    50: "#E4F1E9",
    100: "#DFF7E7",
    200: "#27EF96",
    300: "#10DE82",
    400: "#0EBE6F",
    500: "#144D29",
    600: "#114122",
    700: "#086F42",
    800: "#075C37",
    900: "#064C2E",
  },
  secondary: {
    50: "#FEEED8",
    100: "#FBCB84",
    200: "#FAC16B",
    300: "#F9B653",
    400: "#F8AC3A",
    500: "#F7A426",
    600: "#F69709",
    700: "#DD8808",
    800: "#C57907",
    900: "#AC6A06",
  },
};

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)"
};

const customTheme = extendTheme({
  fonts: {
    heading: "'Open Sans Condensed', sans-serif",
    // body: "'Raleway', sans-serif",
  },
  colors,
  activeLabelStyles,
  styles: {
    global: {
      "html, body, div": {
        color: "gray",
        lineHeight: "tall",
      },
      "h1, h2, h3": {
        color: "primary.600",
        lineHeight: "tall",
        textTransform: "uppercase",
      },
      h2: {
        color: "primary.700",
        lineHeight: "tall",
      },
      a: {
        color: "primary.500",
        textDecoration: "underline",
      },
      "::placeholder": {
        /* Chrome, Firefox, Opera, Safari 10.1+ */ 
        color: "rgb(128, 128, 128)",
        opacity: 1 /* Firefox */,
      },
      "input, select, textarea": {
        borderColor: "secondary.100",
      },
      form: {
        borderColor: "secondary.200",
      },
      footer: {
        backgroundColor: "gray.100",
        minH: "200px",
      },
      "Input.yellow, Select.yellow": {
        borderColor: "secondary.100",
        focusBorderColor: "secondary.500",
      },
      "Input.yellow:hover, Select.yellow:hover": {
        borderColor: "secondary.500",
        // color: "primary.500",
      },
    },
  },
  components: {
    //Input: inputTheme,
    Form: {
      borderColor: "secondary.200",
      border: "2px solid",
      "input, select, textarea": {
        borderColor: "secondary.100",
      },
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "white",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top",
            },
          },
        },
      },
    },
  },
});

export default customTheme;