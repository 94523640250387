
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { Alert, AlertIcon, Box, Checkbox, Button, Center, Input, Textarea, Link } from "@chakra-ui/react";
import { MdSend } from "react-icons/md";
//import Recaptcha from "../utils/reCaptcha";
//import ReCAPTCHA from "react-google-recaptcha";

const EmailContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [alert, setAlert] = useState(null);

  useEffect(() => {
    if (alert) {
      const timer = setTimeout(() => {
        setAlert(null);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [alert]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const sendEmail = async (data) => {
    setIsSubmitting(true);
    try {
      await emailjs.send(
        "service_9wra7fs",
        "template_n0fgjv3",
        data,
        "HTfSlZtu5T8h_NdZZ"
      );
      setAlert({
        status: "success",
        title: "Ďakujeme! Správa bola odoslaná. Budeme vás čoskoro kontaktovať",
      });
      reset();
      setIsSubmitting(false);
    } catch (error) {
      setAlert({
        status: "error",
        title: "Pri odosielaní nastala chyba. Skúste to znovu prosím",
      });
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(sendEmail)}>
        <Box>Meno *</Box>
        <Input
          {...register("meno", { required: true })}
          className="yellow"
          type="text"
          name="meno"
          placeholder="Meno"
          id="meno"
          label="Meno"
          isRequired
          isInvalid={errors.meno}
          mb={4}
          focusBorderColor={"secondary.500"}
        />
        <Box>E-mail *</Box>
        <Input
          {...register("email", { required: true })}
          className="yellow"
          type="email"
          name="email"
          placeholder="@"
          id="meno"
          label="E-mail"
          isRequired
          isInvalid={errors.email}
          mb={4}
          focusBorderColor={"secondary.500"}
        />
        <Box>Telefón</Box>
        <Input
          {...register("telefon")}
          className="yellow"
          type="tel"
          name="telefon"
          placeholder="+421"
          id="telefon"
          label="Telefón"
          isInvalid={errors.telefon}
          mb={4}
          focusBorderColor={"secondary.500"}
        />
        <Box>Správa</Box>
        <Textarea
          {...register("sprava")}
          className="yellow"
          name="sprava"
          id="sprava"
          placeholder="Správa"
          size="md"
          bgColor={"white"}
          focusBorderColor={"secondary.500"}
        />

        {/* <Box mt={4}>
      <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} />
    </Box> */}

        <Checkbox
          {...register("agree", { required: true })}
          isRequired
          borderColor={"green"}
          colorScheme={"white"}
          p={2}
        >
          Súhlasím so spracovaním{" "}
          <Link
            href="/ochrana-osobnych-udajov"
            target={"_blank"}
            style={{ textDecoration: "underline" }}
          >
            osobných údajov
          </Link>
        </Checkbox>
        <Box mt="4">
          {alert && (
            <Alert status={alert.status}>
              <AlertIcon />
              {alert.title}
            </Alert>
          )}
        </Box>
        <Center>
          <Button
            leftIcon={<MdSend />}
            my={8}
            colorScheme="secondary"
            size="md"
            type="submit"
            isLoading={isSubmitting}
            loadingText="Odosielam"
          >
            Odoslať
          </Button>
        </Center>
      </form>
    </>
  );
};
 
export default EmailContactForm;
 
