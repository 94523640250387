import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightAddon,
  SimpleGrid,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Center,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Tooltip,
} from "@chakra-ui/react";
import { QuestionIcon } from "@chakra-ui/icons";
import SEO from "./SEO"

const Splatky = () => { 
  const {
    handleSubmit,
    register,

    formState: { errors, isSubmitting, reset },
  } = useForm();

  const form = useRef();  

  //const urokovaSadzba = 3;

  // Initialize state variables
  const [uver, setUver] = useState(70000);
  const [urokovaSadzba, setUrokovaSadzba] = useState(4);
  const [roky, setRoky] = useState(20);  

  // Calculate monthly payment when input values change
  const calculateTotal = () => {    
    setUver(uver);
    setUrokovaSadzba(urokovaSadzba);
    setRoky(roky);
  }

  const vyskaUveru = uver; //U
  const mesiacov = roky * 12;

  const result2 = urokovaSadzba / 1200;
  const result3_1 = 1 + urokovaSadzba / 1200; 
  const result3_2 = -mesiacov;
  const result3 = 1 - Math.pow(result3_1, result3_2);
  const splatka = vyskaUveru * (result2 / result3);

  const splatkaF = Intl.NumberFormat("sk-SK", {
    style: "currency",
    currency: "EUR",
  }).format(splatka);

  // Handle change events for input fields
  const handleUverChange = (e) => {
    setUver(e.target.value);
  };
  // const handleSadzbaChange = (e) => {
  //   setUrokovaSadzba(parseInt(e.target.value));
  // };
  const handleSadzbaChange = (urokovaSadzba) => setUrokovaSadzba(urokovaSadzba);
  const handleChange = (roky) => setRoky(roky);

  return (
    <section>
      <SEO
        metaTitle="Výpočet výšky splátky úveru - MaxiHypo"
        metaDesc="Vypočítaj a porovnaj si výšku splátky hypotéky. Refinancuj si úver a zníž si splátku hypotéky."
        name="Maxihypo.sk"
        type="article"
        author="maxihypo"
      />    
      <Heading as="h1" textAlign={"center"} my={"16"}>
        Vypočítaj si výšku splátky
      </Heading>
      <Box
        maxW={"960"}
        mx={"auto"}
        my={16}
        p={8}
        borderColor="secondary.100"
        borderWidth={{ sm: 0, md: 0, lg: 1 }}
        borderRadius={"5px"}
      >
        <form ref={form}>
          <SimpleGrid columns={{ sm: 1, md: 2, lg: 2 }} spacing={5} mx={"auto"}>
            <Box>
              {/*first column*/}
              <Box mx="auto" mb={2} color="secondary.500">
                &nbsp;Požadovaná výška úveru&nbsp;
                <Tooltip
                  label="Zadaj sumu, ktorú si chceš požičať"
                  fontSize="md"
                >
                  <QuestionIcon />
                </Tooltip>
              </Box>
              <Box mx="auto" mb={4}>
                <InputGroup borderColor={"secondary.100"}>
                  <Input
                    focusBorderColor={"secondary.500"}
                    type="number"
                    name="incomeFirst"
                    placeholder="Koľko si chceš požičať?"
                    id="incomeFirst"
                    label="Výška úveru"
                    defaultValue={70000}
                    isInvalid={errors.name}
                    onChange={handleUverChange}
                    onKeyUp={calculateTotal}
                    borderColor={"secondary.100"}
                    _hover={{
                      borderColor: "secondary.500",
                      color: "primary.500",
                    }}
                  />
                  <InputRightAddon children="€" />
                </InputGroup>
              </Box>
            </Box>
            {/*second column*/}
            <Box>
              <Box>
                <Box>
                  <Box mb={2} color="secondary.500">
                    &nbsp;Úroková sadzba (%)&nbsp;
                    <Tooltip
                      label="Zadaj úrokovú sadzbu, pri ktorej chceš splátky vypočítať"
                      fontSize="md"
                    >
                      <QuestionIcon />
                    </Tooltip>
                  </Box>
                  <Box mb={4}>
                    <NumberInput
                      allowMouseWheel
                      keepWithinRange
                      focusBorderColor={"secondary.500"}
                      defaultValue={urokovaSadzba}
                      precision={2}
                      step={0.1}
                      name="urok"
                      id="urok"
                      label="Úroková sadzba"
                      isInvalid={errors.name}
                      onChange={handleSadzbaChange}
                      // onKeyUp={calculateTotal}
                      borderColor={"secondary.100"}
                      _hover={{
                        borderColor: "secondary.500",
                        color: "primary.500",
                      }}
                    >
                      <NumberInputField
                        borderColor={"secondary.100"}
                        _hover={{
                          borderColor: "secondary.500",
                          color: "primary.500",
                        }}
                      />
                      <NumberInputStepper
                        borderColor={"secondary.100"}
                        _hover={{
                          borderColor: "secondary.500",
                          color: "primary.500",
                        }}
                      >
                        <NumberIncrementStepper
                          borderColor={"secondary.100"}
                          _hover={{
                            borderColor: "secondary.500",
                            color: "primary.500",
                          }}
                        />
                        <NumberDecrementStepper
                          borderColor={"secondary.100"}
                          _hover={{
                            borderColor: "secondary.500",
                            color: "primary.500",
                          }}
                        />
                      </NumberInputStepper>
                    </NumberInput>
                    {/* <InputGroup borderColor={"secondary.100"}>
                      <Input
                        type="number"
                        name="urok"
                        id="urok"
                        label="Úroková sadzba"
                        defaultValue={4}
                        isInvalid={errors.name}
                        onChange={handleSadzbaChange}
                        onKeyUp={calculateTotal}
                        borderColor={"secondary.100"}
                        _hover={{
                          borderColor: "secondary.500",
                          color: "primary.500",
                        }}
                      />
                      <InputRightAddon children="%" />
                    </InputGroup> */}
                  </Box>
                </Box>
              </Box>
            </Box>
          </SimpleGrid>
          <Center mt={4} mb={2} color="secondary.500">
            &nbsp;Splatnosť úveru v rokoch&nbsp;
            <Tooltip
              label="Zadaj, koľko rokov plánuješ úver splácať"
              fontSize="md"
            >
              <QuestionIcon />
            </Tooltip>
          </Center>
          <Flex>
            <NumberInput
              allowMouseWheel
              keepWithinRange
              focusBorderColor={"secondary.500"}
              maxW="100px"
              mr="2rem"
              value={roky}
              defaultValue={15}
              min={5}
              max={30}
              onChange={handleChange}
              borderColor={"secondary.100"}
              _hover={{
                borderColor: "secondary.500",
                color: "primary.500",
              }}
            >
              <NumberInputField
                borderColor={"secondary.100"}
                _hover={{
                  borderColor: "secondary.500",
                  color: "primary.500",
                }}
              />
              <NumberInputStepper
                borderColor={"secondary.100"}
                _hover={{
                  borderColor: "secondary.500",
                  color: "primary.500",
                }}
              >
                <NumberIncrementStepper
                  borderColor={"secondary.100"}
                  _hover={{
                    borderColor: "secondary.500",
                    color: "primary.500",
                  }}
                />
                <NumberDecrementStepper
                  borderColor={"secondary.100"}
                  _hover={{
                    borderColor: "secondary.500",
                    color: "primary.500",
                  }}
                />
              </NumberInputStepper>
            </NumberInput>
            <Slider
              flex="1"
              focusThumbOnChange={false}
              value={roky}
              defaultValue={15}
              min={5}
              max={30}
              colorScheme="secondary"
              onChange={handleChange}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb
                fontSize="md"
                boxSize="32px"
                children={roky}
                borderColor={"secondary.500"}
                borderWidth={"2px"}
              />
            </Slider>
          </Flex>
        </form>
        <Box bg="secondary.500" px="18px" py="18px" mt={12}>
          <Center fontSize="2xl" color="white">
            Výška tvojej splátky
          </Center>
          <Center fontSize="4xl" color="white">
            {splatkaF}
          </Center>
        </Box>
      </Box>
    </section>
  );
};

export default Splatky;
