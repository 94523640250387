import React, {useEffect} from "react";
import AppRouter from "./AppRouter";

import WithSubnavigation from "./components/Navbar";
import Footer from "./components/Footer";
import CookieConsent, {
  getCookieConsentValue,
  Cookies
} from "react-cookie-consent";
import { initGA } from "./utils/ga-utils";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
} from "@chakra-ui/react";

function VerticallyCenter() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button py={1} px={4} fontSize={"sm"} borderRadius={0} onClick={onOpen}>
        Info
      </Button>

      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Info</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <h2>Pravidlá cookies</h2>
            <p>
              Na našej stránke používame súbory cookies, aby ste sa tu dobre
              cítili. Patria medzi ne aj cookies tretích strán. Tieto cookies
              tretích strán môžu sledovať vaše používanie našej webovej
              lokality.
            </p>            
            <p>Dátum poslednej aktualizácie: 13. Marca 2023</p>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>OK</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default function App() {
  const handleAcceptCookie = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  };
  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };
  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  return (
    <div className="App">      
      <WithSubnavigation />
      <AppRouter />
      <Footer />
      <CookieConsent
        enableDeclineButton
        declineButtonText="Neprijímam"
        flipButtons
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
        location="bottom"
        buttonText="OK"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#fff", boxShadow: "0 -1px 4px 0 #ccc" }}
        buttonStyle={{ color: "#4e503b", fontSize: "15px" }}
        expires={150}
      >
        Používame cookies aby sme pre vás zabezpečili ten najlepší zážitok z
        našich webových stránok. Kliknutím na tlačidlo "OK" akceptujete všetky
        cookies. <VerticallyCenter />
      </CookieConsent>
    </div>
  );
}
