import React, { useState, useEffect } from "react";
import {
    Box,
    Flex,
    Text,
    IconButton,
    Image,
    Stack,
    Collapse,
    Icon,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useDisclosure,
  } from "@chakra-ui/react";
  import {
    HamburgerIcon,
    CloseIcon,
    ChevronDownIcon,
    ChevronRightIcon,
  } from "@chakra-ui/icons";
  
  export default function WithSubnavigation() {
    const { isOpen, onToggle } = useDisclosure();

    const [isSticky, setSticky] = useState(false);
    const handleScroll = () => {
      const windowScrollTop = window.scrollY;
      if (windowScrollTop > 10) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };
    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  
    return (
      <header
        style={{
          background: isSticky ? "#fff" : "#fff",
          width: "100%",
          zIndex: "999",
          top: "0",
          position: isSticky ? "fixed" : "",
          //marginBottom: isSticky ? "0" : "100px"
        }}
      >
        <Flex
          maxW={1290}
          mx="auto"
          bg={useColorModeValue("white", "gray.800")}
          color={useColorModeValue("gray.600", "white")}
          minH={"60px"}
          py={{ base: 2 }}
          px={{ base: 4 }}
          borderBottom={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.900")}
          align={"center"}
        >
          <Flex
            flex={{ base: 1, md: "auto" }}
            ml={{ base: -2 }}
            display={{ base: "flex", md: "none" }}
          >
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? (
                  <CloseIcon w={3} h={3} />
                ) : (
                  <HamburgerIcon w={5} h={5} />
                )
              }
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
            />
          </Flex>
          <Flex flex={{ base: 1 }} justify={{ base: "start", md: "start" }}>
            <Link href={"/"}>
              <Image
                src="https://maxihypo.b-cdn.net/maxiHypo-logo.png"
                alt="MaxiHypo"
                width="100%"
                height="100%"
                style={{ maxWidth: "200px", maxHeight: "30px" }}
              />
            </Link>

            <Flex display={{ base: "none", md: "flex" }} ml={10}>
              <DesktopNav />
            </Flex>
          </Flex>
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          <MobileNav />
        </Collapse>
      </header>
    );
  }
  
  const DesktopNav = () => {
    const linkColor = useColorModeValue("primary.900", "gray.200");
    const linkHoverColor = useColorModeValue("secondary.500", "white");
    const popoverContentBgColor = useColorModeValue("white", "gray.800");
  
    return (
      <nav>
        <Stack direction={"row"} spacing={2}>
          {NAV_ITEMS.map((navItem) => (
            <Box key={navItem.label}>
              <Popover trigger={"hover"} placement={"bottom-start"}>
                <PopoverTrigger>
                  <Link
                    p={2}
                    href={navItem.href ?? "#"}
                    fontSize={"md"}
                    fontWeight={500}
                    color={linkColor}
                    _hover={{
                      textDecoration: "underline",
                      color: linkHoverColor,
                    }}
                    _active={{
                      textDecoration: "underline",
                      color: linkHoverColor,
                    }}
                  >
                    {navItem.label}
                  </Link>
                </PopoverTrigger>

                {navItem.children && (
                  <PopoverContent
                    border={0}
                    boxShadow={"xl"}
                    bg={popoverContentBgColor}
                    p={4}
                    rounded={"xl"}
                    minW={"sm"}
                  >
                    <Stack>
                      {navItem.children.map((child) => (
                        <DesktopSubNav key={child.label} {...child} />
                      ))}
                    </Stack>
                  </PopoverContent>
                )}
              </Popover>
            </Box>
          ))}
        </Stack>
      </nav>
    );
  };
  
  const DesktopSubNav = ({ label, href, subLabel, target, rel }) => {
    return (
      <Link
        href={href}
        target={target}
        rel={rel}
        role={"group"}
        display={"block"}
        p={2}
        rounded={"md"}
        _hover={{ bg: useColorModeValue("secondary.50", "gray.900") }}>
        <Stack direction={"row"} align={"center"}>
          <Box>
            <Text
              transition={"all .3s ease"}
              _groupHover={{ color: "primary.900" }}
              fontWeight={500}>
              {label}
            </Text>
            <Text fontSize={"sm"}>{subLabel}</Text>
          </Box>
          <Flex
            transition={"all .3s ease"}
            transform={"translateX(-10px)"}
            opacity={0}
            _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
            justify={"flex-end"}
            align={"center"}
            flex={1}>
            <Icon color={"primary.900"} w={5} h={5} as={ChevronRightIcon} />
          </Flex>
        </Stack>
      </Link>
    );
  };
  
  const MobileNav = () => {
    return (
      <nav>
        <Stack
          bg={useColorModeValue("white", "gray.800")}
          p={4}
          display={{ md: "none" }}
        >
          {NAV_ITEMS.map((navItem) => (
            <MobileNavItem key={navItem.label} {...navItem} />
          ))}
        </Stack>
      </nav>
    );
  };
  
  const MobileNavItem = ({ label, children, href, target, rel }) => {
    const { isOpen, onToggle } = useDisclosure();
  
    return (
      <Stack spacing={4} onClick={children && onToggle}>
        <Flex
          py={2}          
          as={Link}
          href={href ?? "#"}
          target={target}
          rel={rel}
          justify={"space-between"}
          align={"center"}
          _hover={{
            textDecoration: "none",
          }}>
          <Text
            fontWeight={600}
            color={useColorModeValue("gray.600", "gray.200")}>
            {label}
          </Text>
          {children && (
            <Icon
              as={ChevronDownIcon}
              transition={"all .25s ease-in-out"}
              transform={isOpen ? "rotate(180deg)" : ""}
              w={6}
              h={6}
            />
          )}
        </Flex>
  
        <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
          <Stack
            mt={2}
            pl={4}
            borderLeft={1}
            borderStyle={"solid"}
            borderColor={useColorModeValue("gray.200", "gray.700")}
            align={"start"}>
            {children &&
              children.map((child) => (
                <Link key={child.label} py={2} href={child.href} target={child.target} rel={child.rel}>
                  {child.label}
                </Link>
              ))}
          </Stack>
        </Collapse>
      </Stack>
    );
  };
  
  
  
  const NAV_ITEMS = [
    {
      label: "Vypočítaj si",
      href: "#",
      children: [
        {
          label: "Výšku úveru",
          subLabel: "Orientačný výpočet úveru",
          href: "/vypocitaj-si/vysku-uveru/",
        },
        {
          label: "Výšku splátky",
          subLabel: "Orientačný výpočet mesačnej splátky",
          href: "/vypocitaj-si/vysku-splatky/",
        },
      ],
    },
    {
      label: "Niečo o mne",
      href: "/nieco-o-mne",
    },
    {
      label: "Ako to chodí",
      href: "/ako-to-chodi",
    },
    {
      label: "Za koľko",
      href: "/za-kolko",
    },
    {
      label: "Kontakt",
      href: "/kontakt",
    },
    {
      label: "Užitočné linky",
      href: "#",
      children: [
        {
          label: "Nájdi znalca",
          subLabel: "",
          href: "https://obcan.justice.sk/infosud-registre",
          target: "_blank",
          rel: "noopener",
        },
        {
          label: "Over si agenta",
          subLabel: "",
          href: "https://regfap.nbs.sk/search.php",
          target: "_blank",
          rel: "noopener",
        },
        {
          label: "List vlastníctva",
          subLabel: "",
          href: "https://kataster.skgeodesy.sk/eskn-portal/",
          target: "_blank",
          rel: "noopener",
        },
      ],
    },
    {
      label: "Poistenie",
      href: "#",
      children: [
        {
          label: "PZP",
          subLabel: "",
          href: "https://poistenie.fingo.sk/pzp-kalkulacka?ref=fd10ee8e373b3c1f0f615040a9b754de",
          target: "_blank",
          rel: "noopener",
        },
        {
          label: "Poistenie nehnuteľnosti",
          subLabel: "",
          href: "https://poistenie.fingo.sk/poistenie-majetku?ref=fd10ee8e373b3c1f0f615040a9b754de",
          target: "_blank",
          rel: "noopener",
        },
        {
          label: "Cestovné poistenie",
          subLabel: "",
          href: "https://poistenie.fingo.sk/cestovne-poistenie?ref=fd10ee8e373b3c1f0f615040a9b754de",
          target: "_blank",
          rel: "noopener",
        },
      ],
    },
  ];